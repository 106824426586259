.Toastify__progress-bar--info{
    background: #6b46c1 !important;
}

.Toastify__progress-bar--success{
    background: #6b46c1 !important;
}

.Toastify__progress-bar--error{
    background: #6b46c1 !important;
}

.Toastify__toast-icon svg{
    fill: #6b46c1 !important; 
}

.css-svwggu{
    display: none !important;
}