.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 1rem;
}

.pagination button {
    width: 35px;
    height: 35px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #7500c0;
    /* border-color: #7500c0; */
    border: 1px solid #7500c0;
}

.pagination button.active {
    font-weight: 900;
    /* border-color: #101010; */
    background: #7500c0;
    color: #eee;
}