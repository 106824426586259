.chakra-modal__content{
    max-width: 67% !important;
}

.Toastify__toast--info {
    /* background: #7500c0; */
    padding: 20px 16px 20px 22px;
    line-height: 22px;
}

.Toastify__toast--error {
    padding: 20px 16px 20px 22px;
    line-height: 22px;
}

.bootcamps .chakra-accordion__icon {
    background: #7500c0;
    color: white !important;
    border-radius: 10px;
    align-self: flex-end;
}
.bootcamps .chakra-accordion__button:focus{
    box-shadow: white !important;
    background: white;
}

.bootcamps .chakra-accordion__button:hover{
    box-shadow: white !important;
    background: white;
}

.css-1ygl3x:focus, .css-1ygl3x[data-focus] {
    box-shadow:  0 0 0 3px rgb(255 255 255 / 60%) !important;
}
/* .Toastify__progress-bar--error {
    background: #7500c0 !important;
    background: var(--toastify-color-progress-error);
}


svg {
    width: 100%;
    height: 100%;
    fill: #7500c0 !important;
} */

@media screen and (min-width: 768px) {

.css-11ltc71 {
    padding: 3% 20% 3% 20% !important;
}
}